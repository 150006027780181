<template>
  <div>
    <CommonModal
      v-model="show"
      :modal-size="'modal-lg'"
    >
      <template #body-content>
      <section id="tnc" class="d-flex flex-column flex-sm-row">
        <div class="left-wrap d-flex align-end">
        <div class="left-wrap__block">
            <h1 class="section-title mb-4 mt-4">Terms & Conditions</h1>
            <h3>How We Use the Information We Collect:</h3>
<p>
<ul>
<li>fulfill your requests or orders and process payments for our products and services;</li>
<li>if you give us permission, communicate with you about products and services of Baoger Labs or our business partners that we believe may interest you;</li>
<li>communicate with you about and administer your participation in contests, competitions, prize draws, offers, promotions or special events;</li>
<li>send communications to you about your orders, purchases or accounts with Baoger Lab;</li>
<li>send information to your contacts if you request us to do so;</li>
<li>provide customer support, respond to, and communicate with you about your requests, questions and comments;</li>
<li>enable you to post on our blogs and interact with Baoger Lab through social media;</li>
<li>personalize your experience on our Online Services and food truck technologies, such as providing you with content in which you may be interested, and making navigation to our Sites and Mobile Apps easier;</li>
<li>perform data analytics, including consumer research, trend analysis, and financial analysis;</li>
<li>operate, evaluate and improve our business, including the development of new products and services; determination of the effectiveness of our sales, marketing and advertising efforts; and analysis and improvement of our products, offers, promotions, and Online Services and other technologies;</li>
<li>protect against, identify and prevent fraud and other criminal activity, claims and other liabilities; and comply with applicable law.</li>
</ul>
</p>
<p>
We use automated information for purposes such as to (i) personalize the visitor experience when visit our food trucks or on our Online Services, (ii) deliver content (including advertising) tailored to our users' interests and the manner in which our users browse our Online Services or food truck technologies, and (iii) manage our business. We also use this information to help diagnose technical and service problems; administer our Online Services; identify users of our Online Services; identify a device for fraud prevention purposes, to target advertising or to personalize users’ experiences; and gather demographic information about our users. We also use automated information to determine usage patterns and how we may tailor our Online Services to better meet the needs of our users.
</p>

<h3>How We Share the Personal Information We Collect</h3>
<p>
Baoger Lab does not sell or otherwise share personal information about you, except as described in the Privacy Statement.
</p>
<p>
Baoger Lab may share personal information with vendors that provide services to Baoger Lab’s, such as fulfilling orders, providing data processing and other information technology services, administering promotions, contests and prize draws, conducting research and performing analytics, and personalizing individual Baoger Lab's experiences. We do not authorize these vendors to use the personal information or to disclose it for any purpose other than to perform services on our behalf.
</p>
<p>
Circumstances may arise where, whether for strategic or other business reasons, Baoger Labs decides to sell or transfer all or a portion of our business. We may transfer information we have collected and stored, including personal information, to the party or parties involved in the transaction as part of that transaction.Baoger Lab’s reserves the right to use or disclose personal information as needed to satisfy any law, regulation or legal request, to protect the integrity of our Online Services and food truck technologies, to bring or defend against legal claims, to protect the rights, interests, safety and security of Baoger Lab’s employees or franchisees, or members of the public, or in connection with the investigation of fraud, illegal activity or violation of  Baoger Lab’s policy.
</p>
<h3>Your Choices</h3>
<p>
You can decide not to receive marketing communications from Baoger Lab by contacting us at the address, telephone number or email address provided below. Please note that if you opt out of receiving marketing communications from us, we may still send communications to you concerning your transactions, accounts with Baoger Lab and any contests, competitions or prize draws in which you have enrolled.
</p>

<h3>Access to and Correction and Deletion of Personal Information</h3>
<p>
  If at any time you would like to access, correct, or delete personal information we have collected from you, please contact us at the email or postal address listed below. Please note that we may be required by law to retain certain information. Before Baoger Lab is able to provide you with any information or correct any inaccuracies, we may ask you to verify your identity and/or provide other details to help us respond to your request. If you are below the age of 18, a parent, guardian or a person who is responsible for you may request access to, correction or deletion of your personal data.
</p>

<h3>Targeted Advertising</h3>
<p>
On our Online Services, we may collect information about your online activities for use in providing you with advertising tailored to your individual interests. This section of our term of use provides details and explains how to exercise your choices.
You may see certain ads on other websites because we participate in advertising networks. Ad networks allow us to target our messaging to users through demographic, interest-based and contextual means. These networks track your online activities over time by collecting information through automated means, including through the use of cookies, web server logs, web beacons and other technologies. The networks use this information to show you advertisements that may be tailored to your individual interests. The information our ad networks may collect includes information about your visits to websites that participate in the relevant advertising networks, such as the pages or advertisements you view and the actions you take on the websites. This data collection takes place both on our Online Services and on third-party websites that participate in the ad networks. This process also helps us evaluate our marketing efforts.
</p>
<p>
Links to Other Websites and Social Media
Our Online Services may offer links to third-party websites that are not operated by Baoger Lab. If you visit one of these linked websites, you should review the website’s privacy policy, terms and conditions, and other policies. We are not responsible for the policies and practices of third-parties. Any information you submit to those third-parties is subject to their privacy policy, terms and conditions, and other policies.
</p>

<h3>Information Details</h3>
<p>
We will otherwise treat your personal information as private and confidential and will not disclose your information to anyone outside except where:
<ol>
<li>where you have given permission, where we are required or permitted to do so by law;</li>
<li>where required or authorised by any order of court, tribunal or authority, whether governmental or quasi-governmental with jurisdiction;</li>
<li>where we may transfer rights and obligations pursuant to our agreement(s) with you; and/or</li>
<li>where we are required to meet our obligations to any relevant regulatory authority.</li>
</ol>
</p>

            <p>
Baoger Lab is committed to taking appropriate measures designed to keep personal information secure. We have in place technical, administrative, and physical procedures designed to protect personal information from accidental, unlawful or unauthorized loss, access, disclosure, use, alteration, or destruction. While we take efforts to safeguard our information systems, no website, mobile application, computer system, or transmission of data over the Internet or any other public network can be guaranteed to be 100% secure.
            </p>
        </div>
        </div>
      </section>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import CommonModal from '@/components/Modal';

export default {
  name: 'TnCModal',
  components: {
    CommonModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
}
</script>
<style lang="scss" scoped>
h3 {
  line-height: 1.5rem;
  padding-bottom: 1rem;
}
p {
  line-height: 1.8rem;
  font-size: 0.9rem;
}
</style>