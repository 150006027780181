<template>
  <v-footer id="main-footer" color="var(--primary-black-color)" absolute app inset>
    <div class="d-flex flex-column align-center flex-sm-row w-100">
      <img class="logo mb-3 mb-sm-0 pt-6" src="@/assets/logo-yellow.svg">
      <div class="d-flex flex-column justify-center align-center flex-grow-1">
        <div class="d-flex mb-6 mt-6">
          <img class="social-link cursor-pointer mr-2" src="@/assets/main-page/ig-yellow.svg" alt="ig" onclick="window.open('https://www.instagram.com/baogerlabmy')">
          <img class="social-link cursor-pointer mr-2" src="@/assets/main-page/fb-yellow.svg" alt="fb" onclick="window.open('https://www.facebook.com/baogerlabmy')">
        </div>
        <div class="footer-items-wrap d-flex flex-column flex-sm-row w-100 mb-3">
          <div class="footer-item mb-2 mb-sm-0"><a @click="showTnCModal">TnC</a></div>
          <div class="footer-item mb-2 mb-sm-0"><a @click="showPrivacyPolicyModal">Privacy Policy</a></div>
          <div class="footer-item mb-2 mb-sm-0"><a @click="showRefundPolicyModal">Refund Policy</a></div>
        </div>
        <div class="footer-items-wrap d-flex flex-column flex-sm-row w-100 mb-6">
          <div class="footer-item mb-2 mb-sm-0">Operation Hours: 11:00am - 9:00pm</div>
          <div class="footer-item mb-2 mb-sm-0 d-flex justify-center align-center">
            <img class="footer-icon mr-2" src="@/assets/main-page/mail.svg" alt="mail">
            <a href="mailto:baogerlab@gmail.com">baogerlab@gmail.com</a>
          </div>
          <div class="footer-item mb-2 mb-sm-0 d-flex justify-center align-center">
            <img class="footer-icon mr-2" src="@/assets/main-page/phone.svg" alt="phone">
            ‎+60 3-8051 3018
          </div>
        </div>
        <div class="footer-copyright font-primary-orange mb-2">
          Copyright © {{ yearEst }}<span v-if="yearEst != currentYear"> - {{ currentYear }}</span>. All Rights Reserved by Baoger Lab
        </div>
        <div class="footer-copyright font-primary-orange mb-12">
          No 25, Jln Puteri 2/5, Bandar Puteri, 47100 Puchong, Selangor
        </div>

      </div>
      <TnCModal v-if="tncModalShow" v-model="tncModalShow" />
      <PrivacyPolicyModal v-if="privacyPolicyModalShow" v-model="privacyPolicyModalShow" />
      <RefundPolicyModal v-if="refundPolicyModalShow" v-model="refundPolicyModalShow" />

    </div>
  </v-footer>
</template>

<script>
import TnCModal from './components/TnCModal';
import PrivacyPolicyModal from './components/PrivacyPolicyModal';
import RefundPolicyModal from './components/RefundPolicyModal';
export default {
  name: 'DefaultFooter',
  components: {
    TnCModal,
    PrivacyPolicyModal,
    RefundPolicyModal,
  },
  data() {
    return {
      yearEst: '2021',
      tncModalShow: false,
      privacyPolicyModalShow: false,
      refundPolicyModalShow: false,
    }
  },
  computed: {
    currentYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
  },
  methods: {
    showTnCModal() {
      this.tncModalShow = true;
    },
    showPrivacyPolicyModal() {
      this.privacyPolicyModalShow = true;
    },
    showRefundPolicyModal() {
      this.refundPolicyModalShow = true;
    },
  }
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';

#main-footer {
  min-height: 200px;
  width: 100%;
  padding: 10px 60px;
  @include for-sm {
    padding: 10px 10px;
  }
  .logo {
    max-width: 80px;
    width: 100%;
  }
  .footer-items-wrap {
    // display: grid;
    // grid-gap: 1.2rem;
    // grid-template-columns: repeat(4, 1fr);
    .footer-item {
      color: var(--primary-color);
      font-size: 14px;
      text-align: center;
      width: 33%;
      @include for-sm {
        width: 100%;
      }
    }
    .footer-item a {
      color: var(--primary-color);
      text-decoration: none;
    }
    .footer-icon {
      height: 20px;
      width: 20px;
    }
  }
  .footer-copyright {
    font-size: 14px;
    @include for-sm {
      font-size: 12px;
    }
  }
  .social-link {
    height: 25px;
    width: 25px;
  }
}
</style>