<template>
  <div>
    <CommonModal
      v-model="show"
      :modal-size="'modal-lg'"
    >
      <template #body-content>
      <section id="tnc" class="d-flex flex-column flex-sm-row">
        <div class="left-wrap d-flex align-end">
        <div class="left-wrap__block">
            <h1 class="section-title mb-3">Privacy Policy</h1>
            <p>
            At Baoger Lab, we respect your concerns about privacy. This Privacy Policy describes the personal information practices of Baoger Lab with respect to your personal information when you interact with us in person or via Web sites and mobile apps, including the types of personal information we collect, how we may use this information, and with whom we may share it.
            </p>
<h3>Information that we collect</h3>
            <p>
We may collect personal information about you when you visit one of our food trucks, use Baoger Lab’s Web sites, or mobile device applications (“Mobile Apps”). We refer to our Sites and Apps collectively as “Online Services”.
            </p>
            <p>
	The types of personal information we collect may include:
<ul>
<li>Transaction information, such as product purchased, price, method of payment and payment details.</li>
<li>Account information, such as username, password, and other identifiers or credentials used to access our Online Services or to acquire Baoger Lab’s products and services.</li>
<li>Other personal information you choose to provide when you interact with Baoger Lab.</li>
</ul>
            </p>
            <p>
The circumstances in which you provide personal information may include when you:
<ul>
<li>purchase products at our food trucks or seek for services;</li>
<li>register to use our Online Services;</li>
<li>request to receive marketing or other communications;</li>
<li>participate in our marketing and promotional activities, market surveys and analytics;</li>
<li>use our Wi-Fi networks, kiosks or other in-store technologies;</li>
<li>engage with us through our websites and social media channels;</li>
<li>seek our participation in any organized event or program;</li>
<li>enter in one of our contests, competitions or prize draws;</li>
<li>submit information when providing feedback;</li>
<li>use interactive features of our Online Services; or</li>
<li>communicate with us or our authorized agents.</li>
</ul>
            </p>
            <p>
Where indicated, it is obligatory to provide your personal information to us to enable us to process your participation in the above activities. Should you decline to provide such obligatory personal information, we may not be able to process your request.
Information Collected Through Automated Means:
            </p>
            <p>
We may collect information from your computer system or mobile device through automated technologies when you visit our food trucks or use our Online Services or food truck technologies. These automated technologies may include cookies, local shared objects, and web beacons. Additional information regarding the use of cookies and other automated technologies is provided in this statement below. The information we collect may include your Internet Protocol (IP) address; computer/mobile device operating system and browser type; type of mobile device; mobile device settings; advertising identifier (e.g., IDFAs and IFAs) or similar identifier; the referring website or application; the linked sites, third-party websites, applications or social media where you share Online Services content; and activity related to your use of our Online Services such as the pages you visit on our Sites or in our mobile Apps.
</p>
<p>
The providers of third-party apps, tools, widgets and plug-ins on our Online Services, such as Facebook "Like" buttons, also may use automated means to collect information regarding your interactions with these features. This information is subject to the privacy policies or notices of these providers.
</p>
        </div>
        </div>
      </section>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import CommonModal from '@/components/Modal';

export default {
  name: 'PrivacyPolicyModal',
  components: {
    CommonModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
}
</script>
<style lang="scss" scoped>
h3 {
  line-height: 1.5rem;
  padding-bottom: 1rem;
}
p {
  line-height: 1.8rem;
  font-size: 0.9rem;
}
</style>