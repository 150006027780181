<template>
  <div>
    <CommonModal
      v-model="show"
      :modal-size="'modal-lg'"
    >
      <template #body-content>
      <section id="tnc" class="d-flex flex-column flex-sm-row">
        <div class="left-wrap d-flex align-end">
        <div class="left-wrap__block">
            <h1 class="section-title mb-4 mt-4">Refund Policy</h1>
            <p>
              There is strictly no refund, change or cancellation once the order has been confirmed or payment has been made.
            </p>
        </div>
        </div>
      </section>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import CommonModal from '@/components/Modal';

export default {
  name: 'RefundPolicyModal',
  components: {
    CommonModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
}
</script>
<style lang="scss" scoped>
h3 {
  line-height: 1.5rem;
  padding-bottom: 1rem;
}
p {
  line-height: 1.8rem;
  font-size: 0.9rem;
}
</style>